import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

const newTabContact = () => {
  window.open('https://www.kasem.org/', '_blank');
};


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'SET OF RUBIK', 'SELECT & RANDOM', 'CUSTOM SET', 'Register', 'Sign In', 'Contact Me'];

export default function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    let path = document.location.pathname;
    if (path === '/') {
      document.getElementById('Home')?.classList.add('Mui-selected');
    }
  }, []);

  const click_link = (link: string) => {
    if (link === 'Sign In') {
      document.location.href = '/login';
    } else if (link === 'SELECT & RANDOM') {
      document.location.href = '/random';
    } else if (link === 'Home') {
      document.location.href = '/';
    } else if (link === 'Contact Me') {
      newTabContact();
      console.log('Contact Me');
    } else if(link === 'SET OF RUBIK'){
      document.location.href = '/seethesetofrubik';
    } else if(link === 'CUSTOM SET'){
      document.location.href = '/setfree1';
    } else {
      document.location.href = '/' + link.toLowerCase();
    }
    console.log(link);
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const goToHowToUse = () => {
    document.location.href = "/how_to_use";
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link to={'/'}>
            <img src={require('../assets/images/BackgroundRubik.png')}  alt="" style={{width: '100px'}} />
      </Link>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton onClick={() => {click_link(item)}} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Link to={'/how_to_use'}>
            <img src={require('../assets/ICON/How to use icon.png')}  alt="" style={{width: '30px', cursor: 'pointer'}} />
      </Link>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar component="nav" sx={{backgroundColor: '#1E1E1E'}}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Link to={'/'} >
            <img src={require('../assets/images/logo_new.png')}  alt="" style={{width: '200px'}} />
          </Link>
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block' } }}
          >
          </Typography>
          <Box sx={{ display: { xs: 'none', lg: 'flex' }, width: '100%', justifyContent: 'space-between' }}>
          <Link to={'/'}>
            <img src={require('../assets/images/logo_new.png')}  alt="" style={{width: '200px'}} />
          </Link>
            {navItems.map((item) => (
              <Button id={item} onClick={() => {click_link(item)}} key={item} sx={{ color: '#fff'}}>
                {item}
              </Button>
            ))}
                      <img src={require('../assets/ICON/How to use icon.png')} onClick={goToHowToUse} alt="" style={{width: '30px', height: '30px', marginTop: 'auto', marginBottom: 'auto', cursor: 'pointer'}}/>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}