import React from 'react'
import Button from '@mui/material/Button';

function HowToUse() {
  return (
    <div style={{marginTop: '5rem', textAlign: 'center'}}>
        <Button variant="contained" size='large' onClick={() => {window.location.href = '/user_manual_thai';}} sx={{backgroundColor: '#007bb2'}}>คู่มือการใช้งานเว็บไซต์</Button><br /><br />
        <Button variant="contained" size='large'  onClick={() => {window.location.href = '/user_manual_eng';}} sx={{backgroundColor: '#007bb2'}}>The website user manual</Button>
    </div>
  )
}

export default HowToUse