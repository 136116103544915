import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function UserManualEng() {

	return (
		<div style={{marginTop: '5rem', textAlign: 'center'}}>

	<div
        style={{
            backgroundColor: '#fff',

            /* Fixed position */
            left: 0,
            position: 'fixed',
            top: 0,

            /* Take full size */
            height: '100%',
            width: '100%',
			overflowY: 'scroll',
        }}
    >
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={require('../assets/pdf/The website user manual.pdf')} />
		</Worker>
    </div>
		</div>
	);
}

export default UserManualEng