import React from 'react'
import { Link } from "react-router-dom";

function Log2() {

  return (
    <div style={{marginTop: '5rem'}}>
        <div style={{width: '100%', textAlign: 'center'}}>
        <img src={require("../assets/images/44_4.png")} onClick={() => {window.location.href = '/register'}} style={{cursor: 'pointer'}} className='log_img' alt="" />
        </div>
        <div style={{width: '100%', display: 'flex', marginTop: '3rem', marginBottom: '3rem'}}>
          <Link to={'/time-signature3-4'} style={{color: 'white', marginRight: '1rem'}}><img src={require('../assets/images/Print.png')} alt="" width={50} /> 3/4 Time signature</Link>
          <Link to={'/time-signature4-4'} style={{color: 'rgb(37, 150, 190)', marginRight: 'auto'}}><img src={require('../assets/images/Print.png')} alt="" width={50} /> 4/4 Time signature</Link>
        </div>
    </div>
  )
}

export default Log2