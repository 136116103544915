import React from 'react'
import Slider from '@mui/material/Slider';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import IconButton from '@mui/material/IconButton';

class Metronome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bpm: 100,
            playing: false,
            count: 0,
            color: 'info',
        }
        this.click1 = new Audio("https://daveceddia.com/freebies/react-metronome/click1.wav");
        this.click2 = new Audio("https://daveceddia.com/freebies/react-metronome/click1.wav");
        this.handleBPM = this.handleBPM.bind(this);
        this.updateInterval = this.updateInterval.bind(this);
        this.startStop = this.startStop.bind(this);
        this.playClick = this.playClick.bind(this);
    }

    updateInterval() {  
        const bmpSpeed = 59.52 * 1000 / this.state.bpm;
        this.timer = setInterval(this.playClick, bmpSpeed);
    }

    handleBPM(event) {
        const bpm = event.target.value;
        if (this.state.playing) {
            clearInterval(this.timer);
            this.updateInterval();
            this.setState({
                count: 0,
                bpm
            });
        } else {
            this.setState({
                bpm
            });
        };
        this.updateColor();
    }

    playClick() {
        if (this.state.count === 0) this.click2.play();
        else this.click1.play();
        this.setState({
            count: this.state.count + 1
        });
        console.log(this.state.count);
    }

    updateColor() {
        const bpm = this.state.bpm;
        if(bpm > 230) {
          this.setState({color: 'error'})
        } else if (bpm > 190) {
          this.setState({color: 'warning'})
        } else if (bpm > 150) {
          this.setState({color: 'secondary'})
        } else if (bpm > 110) {
          this.setState({color: 'primary'})
        } else if (bpm > 70) {
          this.setState({color: 'info'})
        } else if (bpm > 40) {
          this.setState({color: 'success'})
        } 
      }

    startStop() {
        if (this.state.playing) {
            clearInterval(this.timer);
            this.setState({
                playing: false
            });
        } else {
            this.updateInterval();
            this.setState({
                count: 0,
                playing: true
            }, this.playClick)
        }
    }


    render() {
        return (
          <div>
              <div className="bpm-slider">
          <p style={{fontSize: '35px', fontWeight: 'bold', color: 'white', textAlign: 'center', marginBottom: '0px'}}>{this.state.bpm}</p>
          <div style={{display: 'flex'}}>
            <RemoveCircleIcon style={{fontSize: '30px', marginRight: '5px'}} color={this.state.color} onClick={() => {
              this.setState({bpm: this.state.bpm - 1})
              this.updateColor()
            }}/>
            <Slider 
              value={this.state.bpm}
              onChange={this.handleBPM}
              min={40}
              max={250}
              step={1}
              color={this.state.color}
            />
            <AddCircleIcon style={{fontSize: '30px', marginLeft: '5px'}} color={this.state.color} onClick={() => {
              this.setState({bpm: this.state.bpm + 1})
              this.updateColor()
            }}/>
          </div>

        </div>
        <div style={{textAlign: 'center'}}>
        <IconButton onClick={this.startStop} id="btn">
          {this.state.playing ? <PauseCircleFilledRoundedIcon style={{fontSize: '70px'}} color={this.state.color}/> : <PlayCircleRoundedIcon style={{fontSize: '70px'}} color={this.state.color}/>}
        </IconButton>
        </div>


          </div>
        );
    }
}


export default Metronome