import React from 'react'
import { useParams } from 'react-router-dom';

function Register() {

  const { number } = useParams();

  const showSelect = () => {
    if(number === '1'){
      return(
<div className="mb-3">
  <div className="card py-3 px-lg-5 h-100">
    <div className="card-body d-flex flex-column">
      <div className="text-center">
        <img src={require('../assets/ICON/1.png')} className="img-fluid  mb-5" alt="Websearch" />
      </div>

      <div className="card-title mb-4 text-center fs-2">Monthly Essential</div>
      <div className="pricing">
        <ul className="list-unstyled">
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 1</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 2</span>
          </li>
        </ul>


      </div>
      <div className="text-center mt-auto mb-4">
        <span className="fw-bold fs-2 ">$5.95</span>/month
      </div>
      <div className="text-center"><button type="button" className="btn btncolor">Select</button></div>

    </div>
  </div>

</div>
      )
    } else if(number === '2'){
      return(
        <div className="mb-3">
  <div className="card py-3 px-lg-5 h-100">
    <div className="card-body d-flex flex-column">
      <div className="text-center">
        <img src={require('../assets/ICON/1.png')} className="img-fluid  mb-5" alt="Websearch" />
      </div>

      <div className="card-title mb-4 text-center fs-2">Monthly Premiun <img src={require('../assets/ICON/10.png')} width={120} height={120} style={{right: '0px', position: 'absolute'}} alt="" /></div>
      <div className="pricing">
        <ul className="list-unstyled">
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 1</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 2</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Printable 3/4</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Printable 4/4</span>
          </li>
        </ul>


      </div>
      <div className="text-center mt-auto mb-4">
        <span className="fw-bold fs-2 ">$7.95</span>/month
      </div>
      <div className="text-center"><button type="button" className="btn btncolor">Select</button></div>

    </div>
  </div>

</div>
      )
    } else if(number === '3'){
      return(
        <div className="mb-3">
  <div className="card py-3 px-lg-5 h-100">
    <div className="card-body d-flex flex-column">
      <div className="text-center">
      <img src={require('../assets/ICON/1.png')} className="img-fluid  mb-5" alt="Websearch" />
      </div>

      <div className="card-title mb-4 text-center fs-2">Yearly Essential</div>
      <div className="pricing">
      <ul className="list-unstyled">
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 1</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 2</span>
          </li>
        </ul>


      </div>
      <div className="text-center mt-auto mb-4">
        <span className="fw-bold fs-2 ">$59.50</span>/year
      </div>
      <div className="text-center"><button type="button" className="btn btncolor">Select</button></div>

    </div>
  </div>
</div>
      )
    } else if(number === '4'){
      return(
        <div className="mb-3">
  <div className="card py-3 px-lg-5 h-100">
    <div className="card-body d-flex flex-column">
      <div className="text-center">
      <img src={require('../assets/ICON/1.png')} className="img-fluid  mb-5" alt="Websearch" />
      </div>

      <div className="card-title mb-4 text-center fs-2">Yearly Premiun <img src={require('../assets/ICON/11.png')} width={120} height={120} style={{right: '0px', position: 'absolute'}} alt="" /></div>
      <div className="pricing">
      <ul className="list-unstyled">
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 1</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Set Free 2</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Printable 3/4</span>
          </li>
          <li className="mb-3">
            <i className="fas fa-check-circle icon-color"></i>
            <span className="small ms-3">Printable 4/4</span>
          </li>
        </ul>


      </div>
      <div className="text-center mt-auto mb-4">
        <span className="fw-bold fs-2 ">$79.50</span>/year
      </div>
      <div className="text-center"><button type="button" className="btn btncolor">Select</button></div>

    </div>
  </div>
</div>
      )
    }
  }


  return (
    <div style={{marginTop: '5rem'}}>
        <div className='row'>
            <div className='col-lg-8'>
              <div>
                 <img src={require("../assets/images/StepRegister.png")} width={'100%'} alt=""/>
              </div>
              <div className='mt-4'>
                  <div className='m-3 bg-light rounded-5 p-5'>
                      <label htmlFor="">Member ID</label>
                      <input type="text" className='form-control' />
                      <br />
                      <label htmlFor="">Password</label>
                      <input type="password" className='form-control' />
                      <br />
                      <label htmlFor="">Confirm Password</label>
                      <input type="password" className='form-control' />
                      <br />
                      <img src={require("../assets/images/Next.png")} style={{cursor: 'pointer'}} width={150} alt="" />
                  </div>
              </div>
            </div>
            <div className='col-lg-4'>
                {showSelect()}
            </div>
        </div>
    </div>
  )
}

export default Register