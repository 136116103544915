import React from 'react'
import { Button } from '@mui/material';

const getimageall = () => {
    let images = [];
    for(var i = 1; i <= 107; i++){
        images.push(require(`../assets/setFreeAll/${i}.png`));
    }
    return images;
}
var imagesAll = getimageall();

function SetFree() {

    const [text1, setText1] = React.useState([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]);
    const [count, setCount] = React.useState(0);


    React.useEffect(() => {
        if(window.localStorage.getItem('setfree1')){
            let data = JSON.parse(window.localStorage.getItem('setfree1'));
            setText1(data);
            setCount(data.length);
        }

        colorDiv();
    }, [text1, count, colorDiv]);

    function saveSetfree() {
        if (count < 54) return alert('Please select 54 images');
        let data = text1;
        window.localStorage.setItem('setfree1', JSON.stringify(data));
        if(window.confirm("Save Success! Do you want to go to Random Page?")){
            window.open("/random", "_self");
          }
        
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function colorDiv() {
        let divall = document.querySelectorAll('#div_1');
        let i = 0;
        divall.forEach((div) => {
            if (i <= count){
                div.style.backgroundColor = 'white';
            }
            i++;
        })
    }

    function selectImage(image) {
        let data = text1;
        if (count < 54) {
            data[count] = image;
            setText1(data);
            setCount(count + 1);
        } else {
            alert('You can select only 54 images');
        }
    }

    function Reset() {
        let data = text1;
        data = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null];
        setText1(data);
        setCount(0);
        window.localStorage.removeItem('setfree1');
        let divall = document.querySelectorAll('#div_1');
        divall.forEach((div) => {
            div.style.backgroundColor = '#abb5b6';
        })

    }

    function imageShow(text){
        if (text !== null){
          if(window.innerWidth < 992) return <img src={text} alt="" width={35} height={35} />
          return <img src={text} alt="" width={55} height={55} />
        }
      }

      const returnAll = () => {
        return (
            <div className='row' style={{marginLeft: 'auto', marginRight: 'auto'}}>{
            imagesAll.map((image, index) => {
                return (
                    <div key={index} className='spring-box-set bg-light col-1' style={{margin: '1px'}} onClick={() => {selectImage(image)}}>{imageShow(image)}</div>
                )
            })}
            </div>
        );
      }


  return (
    <div style={{marginTop: '5rem'}}>
        <div className='row'>
            <div className='col-lg-6'>
                <table style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[0])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[1])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[2])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2} ><Button variant="outlined" disabled sx={{width: '100%', backgroundColor: 'white'}}><font style={{color: 'black'}} id='btn-font'>CUSTOM </font> <font style={{color: 'rgb(37, 150, 190)'}} id='btn-font'> SET 1</font></Button></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[3])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[4])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[5])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[6])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[7])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[8])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[9])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[10])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[11])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[12])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[13])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[14])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[15])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[16])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[17])}</div></td>
                    </tr>
                    <tr>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[18])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[19])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[20])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[21])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[22])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[23])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[24])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[25])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[26])}</div></td>
                    </tr>
                    <tr>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[27])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[28])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[29])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[30])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[31])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[32])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[33])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[34])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[35])}</div></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[36])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[37])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[38])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td id='btn-font'  colSpan={2} style={{color: 'white'}}><img src={require("../assets/ICON/Save File.png")} alt='' onClick={saveSetfree} className='image-set-save' style={{cursor: 'pointer'}} />Save</td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[39])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[40])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[41])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td id='btn-font'  colSpan={2} style={{color: 'white'}}><img src={require("../assets/ICON/5.png")} alt='' onClick={Reset} className='image-set-save' style={{cursor: 'pointer'}} /> Reset</td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[42])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[43])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[44])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[45])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[46])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[47])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[48])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[49])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[50])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[51])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[52])}</div></td>
                        <td><div className='spring-box-set' id='div_1'>{imageShow(text1[53])}</div></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <div className='col-lg-6 mt-2'>
                <div>
                    <h3 className='text-center' style={{color: 'white'}}>107 Patterns for Custom Set</h3>
                </div>
                <div>
                    {returnAll()}
                </div>
            </div>
        </div>
    </div>
  )
}

export default SetFree