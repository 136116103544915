import React from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


function Home() {

  return (
    <div style={{marginTop: '7rem'}}>
        <Grid container spacing={2} sx={{height: '100vh'}}>
            <Grid item md={6} sx={{width: '100%', textAlign: 'center'}}>
            <img src={require("../assets/ICON/2.png")} width={'80%'} alt="" />
            </Grid>
            <Grid item md={6}>
                <p style={{fontSize: '30px', color: 'white', marginBottom: '0px'}}>Creating basic</p>
                <p style={{fontSize: '60px', color: '#61D2E6', marginTop: '0px', marginBottom: '0px'}}>RHYTHMIC</p>
                <p style={{fontSize: '60px', color: '#61D2E6', marginTop: '0px', marginBottom: '0px'}}>SIGHT-READING</p>
                <p style={{color: 'white', marginTop: '0px', marginBottom: '0px'}}>Exercise from random Rubik's cube</p>
                <br />
                <p style={{color: 'white', marginTop: '0px', marginBottom: '0px', maxWidth: '440px'}}>We use the Rubik’s Random System as the foundation for our randomization process, which allows us to calculate probabilities and generate an infinite array of Rhythmic Sight-Reading exercises.</p>
                <br />
                <Button variant="contained" onClick={() => {window.location.href = '/seethesetofrubik';}} sx={{backgroundColor: '#007bb2'}}>SET OF RUBIK</Button>
            </Grid>
        </Grid>
    </div>
  )
}

export default Home