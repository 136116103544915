import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';

import Navbar from './components/Navbar';

import Home from './pages/Home';
import Random from './pages/Random';
import Login from './pages/Login';
import Log1 from './pages/Log1';
import Log2 from './pages/Log2';
import SeeOfRubik from './pages/SeeOfRubik';
import SetFree from './pages/SetFree';
import RegisterPlan from './pages/RegisterPlan';
import Register from './pages/Register';
import HowToUse from './pages/HowToUse';
import UserManualThai from './pages/UserManualThai';
import UserManualEng from './pages/UserManualEng';

function App() {
  return (

    <BrowserRouter>
    <Navbar />

    <Container maxWidth="xl">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/random" element={<Random />} />
      <Route path="/login" element={<Login />} />
      <Route path="/time-signature3-4" element={<Log1 />} />
      <Route path="/time-signature4-4" element={<Log2 />} />
      <Route path="/seethesetofrubik" element={<SeeOfRubik />} />
      <Route path="/setfree1" element={<SetFree />} />
      <Route path="/register" element={<RegisterPlan />} />
      <Route path="/how_to_use" element={<HowToUse />} />
      <Route path="/user_manual_thai" element={<UserManualThai />} />
      <Route path="/user_manual_eng" element={<UserManualEng />} />
      <Route path="/register_select/:number" element={<Register />} />
    </Routes>
    </Container>

    </BrowserRouter>


  );
}

export default App;
