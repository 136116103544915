import React from 'react'
import { animated, useSpring } from "@react-spring/web";
import Button from '@mui/material/Button';
import random_sound from '../assets/sound/rubik3.mp3';
import coin_sound from '../assets/sound/coin.wav';

import Metronome from './Metronome1';


function importAll(r) {
  return r.keys().map(r);
}

const imagesA = importAll(require.context('../assets/A/Set A in 200px/', false, /\.(png)$/));
const imagesB = importAll(require.context('../assets/B/Set B in 200px/', false, /\.(png)$/));
const imagesC = importAll(require.context('../assets/C/Set C in 200px/', false, /\.(png)$/));
const imagesD = importAll(require.context('../assets/D/Set D in 200px/', false, /\.(png)$/));
const imagesE = importAll(require.context('../assets/E/Set E in 200px/', false, /\.(png)$/));
const imagesF = importAll(require.context('../assets/F/Set F in 200px/', false, /\.(png)$/));
const imagesG = importAll(require.context('../assets/G/Set G in 200px/', false, /\.(png)$/));
const imagesH = importAll(require.context('../assets/H/Set H in 200px/', false, /\.(png)$/));
const imagesI = importAll(require.context('../assets/I/Set I in 200px/', false, /\.(png)$/));
const imagesJ = importAll(require.context('../assets/J/Set J in 200px/', false, /\.(png)$/));




function Random() {

    const [text1, setText1] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text2, setText2] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text3, setText3] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text4, setText4] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text5, setText5] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text6, setText6] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text7, setText7] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text8, setText8] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text9, setText9] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text10, setText10] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text11, setText11] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text12, setText12] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text13, setText13] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text14, setText14] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text15, setText15] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [text16, setText16] = React.useState(['', '', '', '', '', '', '', '', '']);
    const [count, setCount] = React.useState(0);

    const [styles, api] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles1, api1] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles2, api2] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles3, api3] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles4, api4] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles5, api5] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles6, api6] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles7, api7] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [styles8, api8] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );

    const [stylesbig1, apibig1] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig2, apibig2] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig3, apibig3] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig4, apibig4] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );  
    const [stylesbig5, apibig5] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig6, apibig6] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig7, apibig7] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig8, apibig8] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig9, apibig9] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig10, apibig10] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig11, apibig11] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig12, apibig12] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig13, apibig13] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig14, apibig14] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig15, apibig15] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );
    const [stylesbig16, apibig16] = useSpring(
      () => ({
        x: 0,
        y: 0,
        rotateZ: 0
      }),
      []
    );



      function delay(milliseconds){
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
      }

  const SetToRubik = (set) => {
    if(SetFree(set) !== null){
     if(count === 0){
      setText1(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_2");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 1){
      setText2(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_3");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 2){
      setText3(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_4");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 3){
      setText4(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_5");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 4){
      setText5(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_6");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 5){
      setText6(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_7");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 6){
      setText7(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_8");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 7){
      setText8(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_9");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 8){
      setText9(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_10");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 9){
      setText10(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_11");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 10){
      setText11(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_12");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 11){
      setText12(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_13");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 12){
      setText13(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_14");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 13){
      setText14(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_15");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 14){
      setText15(SetFree(set));
      setCount(count + 1);
      let div = document.querySelectorAll("#div_16");
      div.forEach((item) => {
        item.style.backgroundColor = "white";
      });
     } else if (count === 15){
      setText16(SetFree(set));
      setCount(count + 1);
     }
    }
  }


  const SetFree = (set) => {
      if (set === 'A') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesA.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesA[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'B') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesB.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesB[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'C') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesC.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesC[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'D') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesD.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesD[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'E') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesE.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesE[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'F') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesF.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesF[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'G') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesG.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesG[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'H') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesH.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesH[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'I') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesI.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesI[data_list1[i]]);
        }
        return data_list;
      } else if(set === 'J') {
        let data_list1 = [];
        for (let i = 0; i < 9; i++) {
            let random_number = Math.floor((Math.random() * Date.now()) % imagesJ.length);
            data_list1.push(random_number);
        }
        let data_list = [];
        for (let i = 0; i < 9; i++) {
            data_list.push(imagesJ[data_list1[i]]);
        }
        return data_list;
      } else if (set === 'setfree1'){
        if(window.localStorage.getItem('setfree1')){
          let data = JSON.parse(window.localStorage.getItem('setfree1'));
          let data_list1 = [];
          for (let i = 0; i < 9; i++) {
              let random_number = Math.floor((Math.random() * Date.now()) % data.length);
              data_list1.push(random_number);
          }
          let data_list = [];
          for (let i = 0; i < 9; i++) {
              data_list.push(data[data_list1[i]]);
          }
          return data_list;
    
        } else {
          if(window.confirm("Please Custom Set 1 Rubik's first ")){
            window.open("/setfree1", "_self");
          }
          return null;
        }
      } else if (set === 'setfree2'){
        if(window.confirm("Please Register for Custom Set 2 Rubik's first")){
          window.open("/register", "_self");
        }
        return null;
      }



  }
  
    const  handleClick = async () => {
      if(count < 16){
        alert("Please complete the previous set");
        return;
      } 
      const audio = new Audio(random_sound);
      const audio1 = new Audio(coin_sound);

      audio.play();
      audio1.volume = 0.7;
      if(window.innerWidth > 768){
      api.start({
        to: [
          { x: 60, y: 60, rotateZ: 0 },
          { x: 60, y: 60, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api1.start({
        to: [
          { x: 0, y: 60, rotateZ: 0 },
          { x: 0, y: 60, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api2.start({
        to: [
          { x: -60, y: 60, rotateZ: 0 },
          { x: -60, y: 60, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api3.start({
        to: [
          { x: 60, y: 0, rotateZ: 0 },
          { x: 60, y: 0, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api4.start({
        to: [
          { x: 0, y: 0, rotateZ: 0 },
          { x: 0, y: 0, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api5.start({
        to: [
          { x: -60, y: 0, rotateZ: 0 },
          { x: -60, y: 0, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api6.start({
        to: [
          { x: 60, y: -60, rotateZ: 0 },
          { x: 60, y: -60, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api7.start({
        to: [
          { x: 0, y: -60, rotateZ: 0 },
          { x: 0, y: -60, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api8.start({
        to: [
          { x: -60, y: -60, rotateZ: 0 },
          { x: -60, y: -60, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
    } else {
      api.start({
        to: [
          { x: 30, y: 30, rotateZ: 0 },
          { x: 30, y: 30, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api1.start({
        to: [
          { x: 0, y: 30, rotateZ: 0 },
          { x: 0, y: 30, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api2.start({
        to: [
          { x: -30, y: 30, rotateZ: 0 },
          { x: -30, y: 30, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api3.start({
        to: [
          { x: 30, y: 0, rotateZ: 0 },
          { x: 30, y: 0, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api4.start({
        to: [
          { x: 0, y: 0, rotateZ: 0 },
          { x: 0, y: 0, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api5.start({
        to: [
          { x: -30, y: 0, rotateZ: 0 },
          { x: -30, y: 0, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api6.start({
        to: [
          { x: 30, y: -30, rotateZ: 0 },
          { x: 30, y: -30, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api7.start({
        to: [
          { x: 0, y: -30, rotateZ: 0 },
          { x: 0, y: -30, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      api8.start({
        to: [
          { x: -30, y: -30, rotateZ: 0 },
          { x: -30, y: -30, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });

    }


      let data_list_old_1 = text1;
      let data_list_old_2 = text2;
      let data_list_old_3 = text3;
      let data_list_old_4 = text4;
      let data_list_old_5 = text5;
      let data_list_old_6 = text6;
      let data_list_old_7 = text7;
      let data_list_old_8 = text8;
      let data_list_old_9 = text9;
      let data_list_old_10 = text10;
      let data_list_old_11 = text11;
      let data_list_old_12 = text12;
      let data_list_old_13 = text13;
      let data_list_old_14 = text14;
      let data_list_old_15 = text15;
      let data_list_old_16 = text16;

      // random set 
      let result1 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result1.includes(num)) {
          result1.push(num);
        }
        if(result1.length === 9) break;
      }
      let result2 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result2.includes(num)) {
          result2.push(num);
        }
        if(result2.length === 9) break;
      }
      let result3 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result3.includes(num)) {
          result3.push(num);
        }
        if(result3.length === 9) break;
      }
      let result4 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result4.includes(num)) {
          result4.push(num);
        }
        if(result4.length === 9) break;
      }
      let result5 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result5.includes(num)) {
          result5.push(num);
        }
        if(result5.length === 9) break;
      }
      let result6 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result6.includes(num)) {
          result6.push(num);
        }
        if(result6.length === 9) break;
      }
      let result7 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result7.includes(num)) {
          result7.push(num);
        }
        if(result7.length === 9) break;
      }
      let result8 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result8.includes(num)) {
          result8.push(num);
        }
        if(result8.length === 9) break;
      }
      let result9 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result9.includes(num)) {
          result9.push(num);
        }
        if(result9.length === 9) break;
      }
      let result10 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result10.includes(num)) {
          result10.push(num);
        }
        if(result10.length === 9) break;
      }
      let result11 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result11.includes(num)) {
          result11.push(num);
        }
        if(result11.length === 9) break;
      }
      let result12 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result12.includes(num)) {
          result12.push(num);
        }
        if(result12.length === 9) break;
      }
      let result13 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result13.includes(num)) {
          result13.push(num);
        }
        if(result13.length === 9) break;
      }
      let result14 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result14.includes(num)) {
          result14.push(num);
        }
        if(result14.length === 9) break;
      }
      let result15 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result15.includes(num)) {
          result15.push(num);
        }
        if(result15.length === 9) break;
      }
      let result16 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 9)
        if(!result16.includes(num)) {
          result16.push(num);
        }
        if(result16.length === 9) break;
      }


      let new_list1 = [];
      let new_list2 = [];
      let new_list3 = [];
      let new_list4 = [];
      let new_list5 = [];
      let new_list6 = [];
      let new_list7 = [];
      let new_list8 = [];
      let new_list9 = [];
      let new_list10 = [];
      let new_list11 = [];
      let new_list12 = [];
      let new_list13 = [];
      let new_list14 = [];
      let new_list15 = [];
      let new_list16 = [];
      for (let i=0;i<9;i++) {
        new_list1.push(data_list_old_1[result1[i]]);
        new_list2.push(data_list_old_2[result2[i]]);
        new_list3.push(data_list_old_3[result3[i]]);
        new_list4.push(data_list_old_4[result4[i]]);
        new_list5.push(data_list_old_5[result5[i]]);
        new_list6.push(data_list_old_6[result6[i]]);
        new_list7.push(data_list_old_7[result7[i]]);
        new_list8.push(data_list_old_8[result8[i]]);
        new_list9.push(data_list_old_9[result9[i]]);
        new_list10.push(data_list_old_10[result10[i]]);
        new_list11.push(data_list_old_11[result11[i]]);
        new_list12.push(data_list_old_12[result12[i]]);
        new_list13.push(data_list_old_13[result13[i]]);
        new_list14.push(data_list_old_14[result14[i]]);
        new_list15.push(data_list_old_15[result15[i]]);
        new_list16.push(data_list_old_16[result16[i]]);
      }

      setText1(new_list1);
      setText2(new_list2);
      setText3(new_list3);
      setText4(new_list4);
      setText5(new_list5);
      setText6(new_list6);
      setText7(new_list7);
      setText8(new_list8);
      setText9(new_list9);
      setText10(new_list10);
      setText11(new_list11);
      setText12(new_list12);
      setText13(new_list13);
      setText14(new_list14);
      setText15(new_list15);
      setText16(new_list16);

      await delay(2000);
      audio.play();
      if(window.innerWidth > 768){
      apibig1.start({
        to: [
          { x: 200, y: 200, rotateZ: 0 },
          { x: 200, y: 200, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig2.start({
        to: [
          { x: 100, y: 200, rotateZ: 0 },
          { x: 100, y: 200, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig3.start({
        to: [
          { x: -100, y: 200, rotateZ: 0 },
          { x: -100, y: 200, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig4.start({
        to: [
          { x: -200, y: 200, rotateZ: 0 },
          { x: -200, y: 200, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig5.start({
        to: [
          { x: 200, y: 50, rotateZ: 0 },
          { x: 200, y: 50, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig6.start({
        to: [
          { x: 100, y: 50, rotateZ: 0 },
          { x: 100, y: 50, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig7.start({
        to: [
          { x: -100, y: 50, rotateZ: 0 },
          { x: -100, y: 50, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig8.start({
        to: [
          { x: -200, y: 50, rotateZ: 0 },
          { x: -200, y: 50, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig9.start({
        to: [
          { x: 200, y: -100, rotateZ: 0 },
          { x: 200, y: -100, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig10.start({
        to: [
          { x: 100, y: -100, rotateZ: 0 },
          { x: 100, y: -100, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig11.start({
        to: [
          { x: -100, y: -100, rotateZ: 0 },
          { x: -100, y: -100, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig12.start({
        to: [
          { x: -200, y: -100, rotateZ: 0 },
          { x: -200, y: -100, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig13.start({
        to: [
          { x: 200, y: -250, rotateZ: 0 },
          { x: 200, y: -250, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig14.start({
        to: [
          { x: 100, y: -250, rotateZ: 0 },
          { x: 100, y: -250, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig15.start({
        to: [
          { x: -100, y: -250, rotateZ: 0 },
          { x: -100, y: -250, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig16.start({
        to: [
          { x: -200, y: -250, rotateZ: 0 },
          { x: -200, y: -250, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
    } else {
      apibig1.start({
        to: [
          { x: 110, y: 140, rotateZ: 0 },
          { x: 110, y: 140, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig2.start({
        to: [
          { x: 45, y: 140, rotateZ: 0 },
          { x: 45, y: 140, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig3.start({
        to: [
          { x: -45, y: 140, rotateZ: 0 },
          { x: -45, y: 140, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig4.start({
        to: [
          { x: -110, y: 140, rotateZ: 0 },
          { x: -110, y: 140, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig5.start({
        to: [
          { x: 110, y: 35, rotateZ: 0 },
          { x: 110, y: 35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig6.start({
        to: [
          { x: 45, y: 35, rotateZ: 0 },
          { x: 45, y: 35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig7.start({
        to: [
          { x: -45, y: 35, rotateZ: 0 },
          { x: -45, y: 35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig8.start({
        to: [
          { x: -110, y: 35, rotateZ: 0 },
          { x: -110, y: 35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig9.start({
        to: [
          { x: 110, y: -35, rotateZ: 0 },
          { x: 110, y: -35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig10.start({
        to: [
          { x: 45, y: -35, rotateZ: 0 },
          { x: 45, y: -35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig11.start({
        to: [
          { x: -45, y: -35, rotateZ: 0 },
          { x: -45, y: -35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig12.start({
        to: [
          { x: -110, y: -35, rotateZ: 0 },
          { x: -110, y: -35, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig13.start({
        to: [
          { x: 120, y: -130, rotateZ: 0 },
          { x: 120, y: -130, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig14.start({
        to: [
          { x: 50, y: -130, rotateZ: 0 },
          { x: 50, y: -130, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig15.start({
        to: [
          { x: -50, y: -130, rotateZ: 0 },
          { x: -50, y: -130, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });
      apibig16.start({
        to: [
          { x: -120, y: -130, rotateZ: 0 },
          { x: -120, y: -130, rotateZ: 360 },
          { x: 0, y: 0, rotateZ: 0 }
        ]
      });

    }


      await delay(1000);

      let data_list_big = [new_list1, new_list2, new_list3, new_list4, new_list5, new_list6, new_list7, new_list8, new_list9, new_list10, new_list11, new_list12, new_list13, new_list14, new_list15, new_list16];
      let result_big1 = [];
      while(true) {
        let num = Math.floor((Math.random() * Date.now()) % 16)
        if(!result_big1.includes(num)) {
          result_big1.push(num);
        }
        if(result_big1.length === 16) break;
      }
      let result_big = [];
      for (let i=0;i<16;i++) {
        result_big[i] = data_list_big[result_big1[i]];
      }

      setText1(result_big[0]);
      setText2(result_big[1]);
      setText3(result_big[2]);
      setText4(result_big[3]);
      setText5(result_big[4]);
      setText6(result_big[5]);
      setText7(result_big[6]);
      setText8(result_big[7]);
      setText9(result_big[8]);
      setText10(result_big[9]);
      setText11(result_big[10]);
      setText12(result_big[11]);
      setText13(result_big[12]);
      setText14(result_big[13]);
      setText15(result_big[14]);
      setText16(result_big[15]);

      await delay(2000);
      audio1.play();
      

    };
  

    function imageShow(text){
      if (text !== ''){
        if(window.innerWidth < 768) return <img src={text} alt="" width={20} height={20} />
        return <img src={text} alt="" width={40} height={40} />
      }
    }

    function ClearData (){
      setText1(['', '', '', '', '', '', '', '', '']);
      setText2(['', '', '', '', '', '', '', '', '']);
      setText3(['', '', '', '', '', '', '', '', '']);
      setText4(['', '', '', '', '', '', '', '', '']);
      setText5(['', '', '', '', '', '', '', '', '']);
      setText6(['', '', '', '', '', '', '', '', '']);
      setText7(['', '', '', '', '', '', '', '', '']);
      setText8(['', '', '', '', '', '', '', '', '']);
      setText9(['', '', '', '', '', '', '', '', '']);
      setText10(['', '', '', '', '', '', '', '', '']);
      setText11(['', '', '', '', '', '', '', '', '']);
      setText12(['', '', '', '', '', '', '', '', '']);
      setText13(['', '', '', '', '', '', '', '', '']);
      setText14(['', '', '', '', '', '', '', '', '']);
      setText15(['', '', '', '', '', '', '', '', '']);
      setText16(['', '', '', '', '', '', '', '', '']);
      setCount(0);
      let div = document.querySelectorAll("#div_2");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_3");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_4");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_5");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_6");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_7");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_8");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_9");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_10");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_11");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_12");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_13");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_14");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_15");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });
      div = document.querySelectorAll("#div_16");
      div.forEach((item) => {
        item.style.backgroundColor = "#abb5b6";
      });

    }
    


    return (
      <div style={{marginTop: '5rem'}}>
        <div className='row'>
          <div className='col-lg-3'>
            <div style={{textAlign: 'center', color: 'white'}} className='div_random'>
              <Button className='random' sx={{margin: '1rem', color: 'white'}} onClick={handleClick}><img src={require("../assets/ICON/ICON.png")} alt='' width={80} /> START RANDOM</Button>
              <Button className='random' sx={{margin: '1rem', color: 'white'}} onClick={ClearData}><img src={require("../assets/ICON/5.png")} alt='' width={80} /> Reset</Button>
            </div>
            <div>
              <div style={{width: '100%', height: '10rem', padding: '2rem', right: '0', left: '0', margin: 'auto', borderRadius: '10px'}}>
              <Metronome 
              minBpm={40}
              maxBpm={250}
              />
              </div>
            </div>
            <div style={{textAlign: 'center'}} className='print'>
              <br />
              <div id='print-text'>
              <h5 onClick={() => {if(count >= 16) {window.location.href = '/time-signature3-4'}}} style={{color: 'white', cursor: 'pointer'}}><img src={require('../assets/images/Print.png')} alt="" width={50} /> 3/4 Time signature</h5>
              <h5 onClick={() => {if(count >= 16) {window.location.href = '/time-signature4-4'}}} style={{color: 'white', cursor: 'pointer'}}><img src={require('../assets/images/Print.png')} alt="" width={50} /> 4/4 Time signature</h5>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div className='col-lg-6'>
<div className='mb-5'>
<div className='d-flex mt-1'>
  <animated.div style={{...stylesbig1, margin: '1px', marginLeft: 'auto'}}>
    <div style={{display: 'flex'}}>
              <animated.div
                id='div_1'
                className="spring-box"
                style={{
                  ...styles,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
                {imageShow(text1[0])}
              </animated.div>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles1,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
                {imageShow(text1[1])}
              </animated.div>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles2,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
                {imageShow(text1[2])}
              </animated.div>
            </div>

            <div style={{display: 'flex'}}>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles3,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
                {imageShow(text1[3])}
              </animated.div>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles4,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
                {imageShow(text1[4])}
              </animated.div>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles5,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
                {imageShow(text1[5])}
              </animated.div>
              </div>

              <div style={{display: 'flex'}}>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles6,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
                {imageShow(text1[6])}
              </animated.div>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles7,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
                {imageShow(text1[7])}
              </animated.div>
              <animated.div
              id='div_1'
                className="spring-box"
                style={{
                  ...styles8,
                  backgroundColor: 'white',
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
                {imageShow(text1[8])}
              </animated.div>
              </div>
  </animated.div>
        {/* 2 */}
  <animated.div style={{...stylesbig2, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text2[0])}
          </animated.div>
          <animated.div
            id='div_2'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text2[1])}
          </animated.div>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text2[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text2[3])}
          </animated.div>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text2[4])}
          </animated.div>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text2[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text2[6])}
          </animated.div>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text2[7])}
          </animated.div>
          <animated.div
          id='div_2'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text2[8])}
          </animated.div>
          </div>
  </animated.div>
        {/* 3 */}
        <animated.div className='' style={{...stylesbig3, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text3[0])}
          </animated.div>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text3[1])}
          </animated.div>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text3[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text3[3])}
          </animated.div>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text3[4])}
          </animated.div>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text3[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text3[6])}
          </animated.div>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text3[7])}
          </animated.div>
          <animated.div
          id='div_3'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text3[8])}
          </animated.div>
          </div>
  </animated.div>
  {/* 4 */}
  <animated.div className='' style={{...stylesbig4, margin: '1px', marginRight: 'auto'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text4[0])}
          </animated.div>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text4[1])}
          </animated.div>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text4[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text4[3])}
          </animated.div>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text4[4])}
          </animated.div>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text4[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text4[6])}
          </animated.div>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text4[7])}
          </animated.div>
          <animated.div
          id='div_4'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text4[8])}
          </animated.div>
          </div>
  </animated.div>
    {/* 5 */}

</div>

{/*---------------------------2-------------------------------------*/}

<div className='d-flex'>
  <animated.div  style={{...stylesbig5, margin: '1px', marginLeft: 'auto'}}>
    <div style={{display: 'flex'}}>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
            {imageShow(text5[0])}
              </animated.div>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles1,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
            {imageShow(text5[1])}
              </animated.div>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles2,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
            {imageShow(text5[2])}
              </animated.div>
            </div>

            <div style={{display: 'flex'}}>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles3,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
            {imageShow(text5[3])}
              </animated.div>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles4,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
            {imageShow(text5[4])}
              </animated.div>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles5,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
            {imageShow(text5[5])}
              </animated.div>
              </div>

              <div style={{display: 'flex'}}>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles6,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
            {imageShow(text5[6])}
              </animated.div>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles7,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
            {imageShow(text5[7])}
              </animated.div>
              <animated.div
              id='div_5'
                className="spring-box"
                style={{
                  ...styles8,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
            {imageShow(text5[8])}
              </animated.div>
              </div>
  </animated.div>
        {/* 2 */}
  <animated.div  style={{...stylesbig6, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text6[0])}
          </animated.div>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text6[1])}
          </animated.div>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text6[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text6[3])}
          </animated.div>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text6[4])}
          </animated.div>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text6[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text6[6])}
          </animated.div>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text6[7])}
          </animated.div>
          <animated.div
          id='div_6'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text6[8])}
          </animated.div>
          </div>
  </animated.div>
        {/* 3 */}
        <animated.div className='' style={{...stylesbig7, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text7[0])}
          </animated.div>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
          {imageShow(text7[1])}
          </animated.div>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
          {imageShow(text7[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text7[3])}
          </animated.div>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
          {imageShow(text7[4])}
          </animated.div>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
          {imageShow(text7[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text7[6])}
          </animated.div>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
          {imageShow(text7[7])}
          </animated.div>
          <animated.div
          id='div_7'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
          {imageShow(text7[8])}
          </animated.div>
          </div>
  </animated.div>
  {/* 4 */}
  <animated.div className='' style={{...stylesbig8, margin: '1px', marginRight: 'auto'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text8[0])}
          </animated.div>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
          {imageShow(text8[1])}
          </animated.div>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
          {imageShow(text8[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text8[3])}
          </animated.div>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
          {imageShow(text8[4])}
          </animated.div>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
          {imageShow(text8[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
          {imageShow(text8[6])}
          </animated.div>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
          {imageShow(text8[7])}
          </animated.div>
          <animated.div
          id='div_8'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
          {imageShow(text8[8])}
          </animated.div>
          </div>
  </animated.div>
    {/* 5 */}

</div>


{/*---------------------------3-------------------------------------*/}

<div className='d-flex'>
  <animated.div className='' style={{...stylesbig9, margin: '1px', marginLeft: 'auto'}}>
    <div style={{display: 'flex'}}>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
            {imageShow(text9[0])}
              </animated.div>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles1,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
              {imageShow(text9[1])}
              </animated.div>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles2,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
              {imageShow(text9[2])}
              </animated.div>
            </div>

            <div style={{display: 'flex'}}>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles3,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
              {imageShow(text9[3])}
              </animated.div>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles4,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
              {imageShow(text9[4])}
              </animated.div>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles5,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
              {imageShow(text9[5])}
              </animated.div>
              </div>

              <div style={{display: 'flex'}}>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles6,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
              {imageShow(text9[6])}
              </animated.div>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles7,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
              {imageShow(text9[7])}
              </animated.div>
              <animated.div
              id='div_9'
                className="spring-box"
                style={{
                  ...styles8,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
              {imageShow(text9[8])}
              </animated.div>
              </div>
  </animated.div>
        {/* 2 */}
  <animated.div className='' style={{...stylesbig10, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text10[0])}
          </animated.div>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text10[1])}
          </animated.div>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text10[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text10[3])}
          </animated.div>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text10[4])}
          </animated.div>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text10[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text10[6])}
          </animated.div>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text10[7])}
          </animated.div>
          <animated.div
          id='div_10'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text10[8])}
          </animated.div>
          </div>
  </animated.div>
        {/* 3 */}
        <animated.div className='' style={{...stylesbig11, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text11[0])}
          </animated.div>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text11[1])}
          </animated.div>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text11[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text11[3])}
          </animated.div>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text11[4])}
          </animated.div>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text11[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text11[6])}
          </animated.div>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text11[7])}
          </animated.div>
          <animated.div
          id='div_11'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text11[8])}
          </animated.div>
          </div>
  </animated.div>
  {/* 4 */}
  <animated.div className='' style={{...stylesbig12, margin: '1px', marginRight: 'auto'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text12[0])}
          </animated.div>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text12[1])}
          </animated.div>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text12[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text12[3])}
          </animated.div>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text12[4])}
          </animated.div>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text12[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text12[6])}
          </animated.div>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text12[7])}
          </animated.div>
          <animated.div
          id='div_12'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text12[8])}
          </animated.div>
          </div>
  </animated.div>
    {/* 5 */}

</div>


{/*---------------------------4-------------------------------------*/}

<div className='d-flex'>
  <animated.div className='' style={{...stylesbig13, margin: '1px', marginLeft: 'auto'}}>
    <div style={{display: 'flex'}}>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
                {imageShow(text13[0])}
              </animated.div>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles1,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
                {imageShow(text13[1])}
              </animated.div>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles2,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
                {imageShow(text13[2])}
              </animated.div>
            </div>

            <div style={{display: 'flex'}}>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles3,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
                {imageShow(text13[3])}
              </animated.div>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles4,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
                {imageShow(text13[4])}
              </animated.div>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles5,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
                {imageShow(text13[5])}
              </animated.div>
              </div>

              <div style={{display: 'flex'}}>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles6,
                  cursor: "pointer",
                  margin: '1px',
                  marginLeft: 'auto',
                }}
              >
                {imageShow(text13[6])}
              </animated.div>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles7,
                  cursor: "pointer",
                  margin: '1px'
                }}
              >
                {imageShow(text13[7])}
              </animated.div>
              <animated.div
              id='div_13'
                className="spring-box"
                style={{
                  ...styles8,
                  cursor: "pointer",
                  margin: '1px',
                  marginRight: 'auto'
                }}
              >
                {imageShow(text13[8])}
              </animated.div>
              </div>
  </animated.div>
        {/* 2 */}
  <animated.div className='' style={{...stylesbig14, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text14[0])}
          </animated.div>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text14[1])}
          </animated.div>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text14[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text14[3])}
          </animated.div>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text14[4])}
          </animated.div>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text14[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text14[6])}
          </animated.div>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text14[7])}
          </animated.div>
          <animated.div
          id='div_14'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text14[8])}
          </animated.div>
          </div>
  </animated.div>
        {/* 3 */}
        <animated.div className='' style={{...stylesbig15, margin: '1px'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text15[0])}
          </animated.div>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text15[1])}
          </animated.div>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text15[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text15[3])}
          </animated.div>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text15[4])}
          </animated.div>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text15[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text15[6])}
          </animated.div>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text15[7])}
          </animated.div>
          <animated.div
          id='div_15'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text15[8])}
          </animated.div>
          </div>
  </animated.div>
  {/* 4 */}
  <animated.div className='' style={{...stylesbig16, margin: '1px', marginRight: 'auto'}}>
          <div style={{display: 'flex'}}>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text16[0])}
          </animated.div>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles1,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text16[1])}
          </animated.div>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles2,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text16[2])}
          </animated.div>
        </div>

        <div style={{display: 'flex'}}>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles3,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text16[3])}
          </animated.div>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles4,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text16[4])}
          </animated.div>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles5,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text16[5])}
          </animated.div>
          </div>

          <div style={{display: 'flex'}}>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles6,
              cursor: "pointer",
              margin: '1px',
              marginLeft: 'auto',
            }}
          >
            {imageShow(text16[6])}
          </animated.div>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles7,
              cursor: "pointer",
              margin: '1px'
            }}
          >
            {imageShow(text16[7])}
          </animated.div>
          <animated.div
          id='div_16'
            className="spring-box"
            style={{
              ...styles8,
              cursor: "pointer",
              margin: '1px',
              marginRight: 'auto'
            }}
          >
            {imageShow(text16[8])}
          </animated.div>
          </div>
  </animated.div>
    {/* 5 */}

</div>

</div>
</div>
          <div className='col-lg-3'>
            <div className='set'>
            <Button variant="outlined" onClick={() => {SetToRubik('A')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>A</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('B')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>B</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('C')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>C</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('D')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>D</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('E')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>E</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('F')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>F</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('G')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>G</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('H')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>H</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('I')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>I</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('J')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>J</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('setfree1')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>CUSTOM SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>1</font></Button>
            <Button variant="outlined" onClick={() => {SetToRubik('setfree2')}} sx={{margin: '4px', width: '130px'}}><font style={{color: 'white'}}>CUSTOM SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>2</font></Button>
          </div>
          </div>
        </div>


      </div>
    );
}

export default Random