import React from 'react'
import Spline from '@splinetool/react-spline';
import Button from '@mui/material/Button';

const listOfRubik = ['https://prod.spline.design/TIpODePnsKeAAbG8/scene.splinecode', 'https://prod.spline.design/TeUvHrvhcyeSXUFX/scene.splinecode',
'https://prod.spline.design/IxY3LpBr6s2of9dx/scene.splinecode', 'https://prod.spline.design/XZG-iZQ8rfUIAoZH/scene.splinecode',
'https://prod.spline.design/0EDWhs8KDX4G3ryJ/scene.splinecode', 'https://prod.spline.design/nNmW1loIOhi-41bu/scene.splinecode',
'https://prod.spline.design/kDCG-vfcQSux1gCn/scene.splinecode', 'https://prod.spline.design/UXfHgOMlrKqdJ9z4/scene.splinecode',
'https://prod.spline.design/5b5dE4MPylqPlnpi/scene.splinecode', 'https://prod.spline.design/N8ALMz5-VK8EauAV/scene.splinecode'
];

function SeeOfRubik() {

  const [rubik, SetRubik] = React.useState(listOfRubik[0]);
  const [click, SetClick] = React.useState(0);

  React.useEffect(() => {
    buttonOutline();
    if(click === 0){
      document.getElementById('a').style.backgroundColor = 'white';
      document.getElementById('f_a').style.color = 'black';
    } else if(click === 1){
      document.getElementById('b').style.backgroundColor = 'white';
      document.getElementById('f_b').style.color = 'black';
    } else if(click === 2){
      document.getElementById('c').style.backgroundColor = 'white';
      document.getElementById('f_c').style.color = 'black';
    } else if(click === 3){
      document.getElementById('d').style.backgroundColor = 'white';
      document.getElementById('f_d').style.color = 'black';
    } else if(click === 4){
      document.getElementById('e').style.backgroundColor = 'white';
      document.getElementById('f_e').style.color = 'black';
    } else if(click === 5){
      document.getElementById('f').style.backgroundColor = 'white';
      document.getElementById('f_f').style.color = 'black';
    } else if(click === 6){
      document.getElementById('g').style.backgroundColor = 'white';
      document.getElementById('f_g').style.color = 'black';
    } else if(click === 7){
      document.getElementById('h').style.backgroundColor = 'white';
      document.getElementById('f_h').style.color = 'black';
    } else if(click === 8){
      document.getElementById('i').style.backgroundColor = 'white';
      document.getElementById('f_i').style.color = 'black';
    } else if(click === 9){
      document.getElementById('j').style.backgroundColor = 'white';
      document.getElementById('f_j').style.color = 'black';
    }
  }, [click]);
  
  const buttonOutline = () => {
    document.getElementById('a').style.backgroundColor = '#1E1E1E';
    document.getElementById('b').style.backgroundColor = '#1E1E1E';
    document.getElementById('c').style.backgroundColor = '#1E1E1E';
    document.getElementById('d').style.backgroundColor = '#1E1E1E';
    document.getElementById('e').style.backgroundColor = '#1E1E1E';
    document.getElementById('f').style.backgroundColor = '#1E1E1E';
    document.getElementById('g').style.backgroundColor = '#1E1E1E';
    document.getElementById('h').style.backgroundColor = '#1E1E1E';
    document.getElementById('i').style.backgroundColor = '#1E1E1E';
    document.getElementById('j').style.backgroundColor = '#1E1E1E';
    document.getElementById('f_a').style.color = 'white';
    document.getElementById('f_b').style.color = 'white';
    document.getElementById('f_c').style.color = 'white';
    document.getElementById('f_d').style.color = 'white';
    document.getElementById('f_e').style.color = 'white';
    document.getElementById('f_f').style.color = 'white';
    document.getElementById('f_g').style.color = 'white';
    document.getElementById('f_h').style.color = 'white';
    document.getElementById('f_i').style.color = 'white';
    document.getElementById('f_j').style.color = 'white';
  }

  return (
    <div>
        <div className='row'>
            <div className='col-lg-6' style={{height: '100vh'}}>
              <Spline scene={rubik} />
            </div>
            <div className='col-lg-6 btn-set'>
            <Button variant="outlined" className='btn-see' id='a' onClick={() => {SetRubik(listOfRubik[0]); SetClick(0)}} sx={{margin: '20px'}}><font id="f_a" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>A</font></Button>
            <Button variant="outlined" className='btn-see' id='b' onClick={() => {SetRubik(listOfRubik[1]); SetClick(1)}} sx={{margin: '20px'}}><font id="f_b" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>B</font></Button>
            <Button variant="outlined" className='btn-see' id='c' onClick={() => {SetRubik(listOfRubik[2]); SetClick(2)}} sx={{margin: '20px'}}><font id="f_c" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>C</font></Button>
            <Button variant="outlined" className='btn-see' id='d' onClick={() => {SetRubik(listOfRubik[3]); SetClick(3)}} sx={{margin: '20px'}}><font id="f_d" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>D</font></Button>
            <Button variant="outlined" className='btn-see' id='e' onClick={() => {SetRubik(listOfRubik[4]); SetClick(4)}} sx={{margin: '20px'}}><font id="f_e" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>E</font></Button>
            <Button variant="outlined" className='btn-see' id='f' onClick={() => {SetRubik(listOfRubik[5]); SetClick(5)}} sx={{margin: '20px'}}><font id="f_f" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>F</font></Button>
            <Button variant="outlined" className='btn-see' id='g' onClick={() => {SetRubik(listOfRubik[6]); SetClick(6)}} sx={{margin: '20px'}}><font id="f_g" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>G</font></Button>
            <Button variant="outlined" className='btn-see' id='h' onClick={() => {SetRubik(listOfRubik[7]); SetClick(7)}} sx={{margin: '20px'}}><font id="f_h" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>H</font></Button>
            <Button variant="outlined" className='btn-see' id='i' onClick={() => {SetRubik(listOfRubik[8]); SetClick(8)}} sx={{margin: '20px'}}><font id="f_i" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>I</font></Button>
            <Button variant="outlined" className='btn-see' id='j' onClick={() => {SetRubik(listOfRubik[9]); SetClick(9)}} sx={{margin: '20px'}}><font id="f_j" style={{color: 'white'}}>SET</font> <font style={{color: 'rgb(37, 150, 190)'}}>J</font></Button>
            </div>
        </div>
        
    </div>
  )
}

export default SeeOfRubik