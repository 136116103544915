import React from 'react'

function Login() {

  return (
    <div style={{marginTop: '7rem', width: '100%', display:'flex'}}>
                  <div className='bg-light rounded-5 p-5 row' style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                    <div className='col-lg-10'>
                    <label htmlFor="">Member ID</label>
                      <input type="text" className='form-control' />
                      <br />
                      <label htmlFor="">Password</label>
                      <input type="password" className='form-control' />
                      <br />
                    </div>
                    <div className='col-lg-2'>
                      <button className='btn' style={{backgroundColor: '#2DCADF', cursor: 'pointer', width: '100%', height: '5rem', marginTop: '40px'}}>
                      <img src={require("../assets/images/loginlogo.png")} style={{}} height={'100%'} width={'100%'}  alt="" />
                      </button>
                    </div>
                  </div>
    </div>
  )
}

export default Login